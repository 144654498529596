var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "container" }, [
        _c("h3", [_vm._v("免責事項")]),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "1.当社は、本サービスにコンテンツを掲載するにあたって、その内容、機能等について細心の注意を払っておりますが、コンテンツの内容が正確であるかどうか、最新のものであるかどうか、安全なものであるか等について保証をするものではなく、何らの責任を負うものではありません。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "2.当社は事前に通知することなく本サービスに掲載した情報の訂正、修正、追加、中断、削除等をいつでも行うことができるものとします。本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "3.当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令又は業界団体の内部規則等に適合すること、及び不具合が生じないことについて、何ら保証するものではありません。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "4.当社は、利用者の本サービスの利用環境について一切関与せず、また一切の責任を負いません。本サービスの機能は利用環境により制限が発生する可能性があり、常に完全に動作することを保障しません。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "5.当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "6.利用者は、本サービスの利用規約及び運用方針の変更等に伴い、本サービスの一部又は全部の利用が制限される可能性があることをあらかじめ了承するものとします。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "7.本サービスにおいてユーザーが登録する記録・データの全てにおいて、保管、保存、バックアップ等を含む一切の責任を負いません。ユーザー自身でバックアップ等を行うものとします。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "8.当社は、本サービスを利用したことにより直接的又は間接的に利用者に発生した損害について、一切賠償責任を負いません。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "9.当社は、利用者その他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、当社が係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "10.第8項及び前項の規定は、当社に故意又は重過失が存する場合又は契約書が消費者契約法上の消費者に該当する場合には適用しません。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "11.前項が適用される場合であっても、当社は、過失（重過失を除きます。）による行為によって利用者に生じた損害のうち、特別な事情から生じた損害については、一切賠償する責任を負わないものとします。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "12.第10項が適用される場合であっても、当社の過失（重過失を除きます。）により本サービスの利用に関し当社が損害賠償責任を負う場合、当該損害が発生した月に利用者から受領した利用額を限度として賠償責任を負うものとします。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "13.利用者と他の利用者との間の紛争及びトラブルについて、当社は一切責任を負わないものとします。利用者と他の利用者でトラブルになった場合でも、両者同士の責任で解決するものとし、当社には一切の請求をしないものとします。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "14.利用者は、本サービスの利用に関連し、他の利用者に損害を与えた場合又は第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償又はかかる紛争を解決するものとし、当社には一切の迷惑や損害を与えないものとします。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "15.利用者の行為により、第三者から当社が損害賠償等の請求をされた場合には、利用者の費用（弁護士費用）と責任で、これを解決するものとします。当社が、当該第三者に対して、損害賠償金を支払った場合には、利用者は、当社に対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "section-box" }, [
          _vm._v(
            "16.利用者が本サービスの利用に関連して当社に損害を与えた場合、利用者の費用と責任において当社に対して損害を賠償（訴訟費用及び弁護士費用を含む）するものとします。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }, [_vm._v("制定：2020年5月31日")]),
        _c("p", { staticClass: "my-4 text-right" }, [_vm._v("以上")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }